<template>
    <v-app>
        <main-notification-feed></main-notification-feed>
        <v-main>
            <router-view/>
        </v-main>
        <p class="text-disabled">{{ version }}</p>
    </v-app>
</template>

<script>
import MainNotificationFeed from './components/MainNotificationFeed.vue';

export default {
    components: { MainNotificationFeed },
    setup() {
        const version = process.env.VUE_APP_VERSION;

        return { version };
    }
};
</script>