import { createI18n } from 'vue-i18n';

const messagesEn = {
    nav: {
        home: 'Home',
        login: 'Login',
        logout: 'Logout'
    },
    user: {
        name: 'Name',
        email: 'E-mail',
        password: 'Password',
        language: 'Language',
        password_again: 'Password Again',
        _2fa_code: '2FA Code',
    },
    login: {
        company_login: 'Company Login',
        sign_in: 'Sign In',
        forgot_password: 'Forgotten Password',
        lost_2fa: 'Lost 2FA',
        continue: 'Continue'
    },
    accept_invite: {
        register_account: 'Register Account',
        register: 'Register'
    },
    reset_password: {
        reset_password: 'Reset Password',
        save: 'Save'
    },
    forgot_password_notif: {
        notif: 'An E-mail containing your next steps is going to arrive soon, you may close this page now and follow the instructions in the E-mail you receive.'
    },
    forgot_password: {
        forgot_password: 'Forgot Password',
        back_to_login: 'Back to login',
        reset: 'Reset'
    },
    util: {
        loading: 'Loading',
        tip: 'Tip',
        tip_text: 'In the future open <span class="font-weight-bold">mmm2020.hu</span> to start a session.'
    }
};

const messagesHu = {
    nav: {
        home: 'Főoldal',
        login: 'Bejelentkezés',
        logout: 'Kilépés'
    },
    user: {
        name: 'Név',
        email: 'E-mail',
        password: 'Jelszó',
        language: 'Nyelv',
        password_again: 'Jelszó Megerősítése',
        _2fa_code: '2FA Kód',
    },
    login: {
        company_login: 'Céges Bejelentkezés',
        sign_in: 'Bejelentkezés',
        forgot_password: 'Elfelejtett Jelszó',
        lost_2fa: 'Elvesztett 2FA',
        continue: 'Következő'
    },
    accept_invite: {
        register_account: 'Regisztráció',
        register: 'Regisztráció'
    },
    reset_password: {
        reset_password: 'Jelszó Visszaállítása',
        save: 'Mentés'
    },
    forgot_password_notif: {
        notif: 'Ha helyes E-mail címet írtál be akkor hamarosan kapni fogsz egy üzenetet, amelyben a következő utasítások szerepelnek.'
    },
    forgot_password: {
        forgot_password: 'Elfelejtett jelszó',
        back_to_login: 'Vissza a bejelentkezéshez',
        reset: 'Visszaállítás'
    },
    util: {
        loading: 'Töltés',
        tip: 'Tipp',
        tip_text: 'Amennyiben már regisztráltál, akkor nyisd meg az <span class="font-weight-bold">mmm2020.hu</span> címet a munkamenet kezdéséhez.'
    }
};

const messages = {
    en: messagesEn,
    hu: messagesHu
}

const i18n = createI18n({
    fallbackLocale: 'en',
    locale: 'hu',
    messages
});

export default i18n;
