<template>
<v-row class="justify-center align-lg-center align-md-center h-screen">
    <v-col cols="12" md="8" lg="5" xl="3">
        <v-row no-gutters justify="center">
            <v-card elevation="4" class="w-100" max-width="30rem">
                <slot></slot>
            </v-card>
        </v-row>
    </v-col>
</v-row>
</template>