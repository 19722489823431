import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import i18n from './i18n'

axios.defaults.headers.post['Content-Type'] ='application/json';
axios.defaults.headers.post['Accept'] ='application/json';
axios.defaults.withCredentials = true;
axios.defaults.xsrfCookieName = process.env.VUE_APP_XSRF_COOKIE;
axios.defaults.xsrfHeaderName = process.env.VUE_APP_XSRF_HEADER;
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.headers.common['X-PUB-VERSION'] = process.env.VUE_APP_VERSION;

// Vuetify
import 'vuetify/styles'
import '@mdi/font/css/materialdesignicons.css'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const customTheme = {
    dark: false,
    colors: {
        background: '#DDDDDD',
        surface: '#FFFFFF',
        primary: '#00ABB3',
        secondary: '#3C4048'
    },
};

const vuetify = createVuetify({
    components,
    directives,
    theme: {
        defaultTheme: 'customTheme',
        themes: {
            customTheme
        }
    }
})

createApp(App)
    .use(router)
    .use(store)
    .use(i18n)
    .use(vuetify)
    .use(VueAxios, axios)
    .mount('#app')
