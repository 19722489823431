<template>
    <v-fade-transition>
        <v-alert v-model="notif.shown" :type="getType()" variant="elevated" border="start" elevation="5" :title="notif.title" width="24em" close-icon="mdi-close" closable>
            {{ notif.body }}
        </v-alert>
    </v-fade-transition>
</template>

<script>
import { onMounted } from '@vue/runtime-core';
import { useStore } from 'vuex';

export default {
    components: {  },
    props: [ 'notif' ],
    setup(props) {
        const store = useStore();

        let dropTimeout = null;
        let fadeTimeout = null;

        onMounted(() => {
            dropTimeout = setTimeout(() => {
                store.dispatch('dropNotification', props.notif.id);
            }, 7000);

            fadeTimeout = setTimeout(() => {
                props.notif.shown = false;
            }, 4000);
        });

        const getType = () => {
            return props.notif.type;
        };

        const dismiss = (evt) => {
            evt.stopPropagation();
            store.dispatch('dropNotification', props.notif.id);
        }

        const resetTimeouts = () => {
            if(dropTimeout != null) {
                clearTimeout(dropTimeout);
                dropTimeout = null;
            }

            if(fadeTimeout != null) {
                clearTimeout(fadeTimeout);
                fadeTimeout = null;
                props.notif.state = 0;
            }
        }

        return { getType, dismiss, resetTimeouts };
    }
}
</script>
