import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: function() {
            return import('../views/ForgotPasswordView.vue')
        }
    },
    {
        path: '/reset-password/:token',
        name: 'reset-password',
        component: function() {
            return import('../views/ResetPasswordView.vue')
        }
    },
    {
        path: '/2fa-challenge',
        name: '2fa-challenge',
        component: function() {
            return import('../views/TwoFactorView.vue')
        }
    },
    {
        path: '/accept-invite/:token',
        name: 'accept-invite',
        component: function() {
            return import('../views/AcceptInviteView.vue')
        }
    }
]

const router = createRouter({
    mode: 'history',
    history: createWebHistory(),
    routes
})

export default router
