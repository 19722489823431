<template>
    <auth-form>
        <v-form id="login-form" @submit.prevent="onSubmit">
            <v-card-title class="text-center pa-0">
                <v-sheet color="primary" class="pa-5">
                    <v-icon icon="mdi-account" class="mr-2"></v-icon>{{ $t('login.company_login') }}
                </v-sheet>
            </v-card-title>
            <v-card-text class="mt-5">
                <v-row>
                    <v-col>
                        <v-text-field variant="outlined" prepend-inner-icon="mdi-email" :label="$t('user.email')" v-model="loginData.email" autocomplete="email" role="email"></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field variant="outlined" prepend-inner-icon="mdi-key" :label="$t('user.password')" type="password" v-model="loginData.password" autocomplete="password" role="password"></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-row no-gutters class="pa-2 align-end" justify="space-between">
                    <v-col>
                        <a href="#" @click.prevent="forgotPassword">{{ $t('login.forgot_password') }}?</a>
                    </v-col>
                    <v-btn variant="flat" prepend-icon="mdi-send" class="px-5" color="primary" :loading="loading" form="login-form" type="submit">{{ $t('login.sign_in') }}</v-btn>
                </v-row>
            </v-card-actions>
        </v-form>
    </auth-form>
</template>

<script>
import { useRouter } from 'vue-router';
import AuthForm from '@/components/AuthForm.vue';
import { ref } from 'vue';
import { restful } from '@/composables/restful';

export default {
    components: { AuthForm },
    setup() {
        const router = useRouter();
        const loginData = ref({
            email: '',
            password: ''
        });

        const { get, post, content, loading } = restful('api/v1/status');

        get({
            morph: (data) => {
                if(data.user != null) {
                    window.location = process.env.VUE_APP_INT_URL;
                    return;
                }

                if(data.two_factor) {
                    router.push({ name: '2fa-challenge' });
                }
            }
        });

        const onSubmit = async () => {
            await post({
                url: 'api/v1/login',
                data: loginData.value,
                morph: (data) => {
                    if(!data.two_factor) {
                        window.location = process.env.VUE_APP_INT_URL;
                    } else {
                        router.push({ name: '2fa-challenge' });
                    }
                }
            });
        };

        const forgotPassword = () => {
            router.push({name: 'forgot-password'});
        };

        return { loading, loginData, forgotPassword, onSubmit };
    },
};
</script>
