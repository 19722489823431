import { createStore } from 'vuex'

export default createStore({
    state: {
        notifications: [],
        notificationId: 0
    },
    getters: { },
    mutations: {
        notify(state, payload) {
            state.notifications = [payload].concat(state.notifications);
        },
        dropNotification(state, id) {
            for(var i = 0; i < state.notifications.length; i++) {
                if(state.notifications[i].id == id) {
                    state.notifications.splice(i, 1);
                    return;
                }
            }
        }
    },
    actions: {
        notify(context, data) {
            data['id'] = context.state.notificationId++;
            context.commit('notify', data);
        },
        dropNotification(context, id) {
            context.commit('dropNotification', id);
        }
    },
    modules: {
    }
})
