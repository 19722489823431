<template>
<div class="d-flex justify-end" style="position: fixed; z-index: 5000; flex-direction: column; flex-wrap: wrap-reverse; top: 5em; right: 5em; max-height: 100vh;">
    <notification class="mx-3 mb-3" v-for="notif in notifications" :key="notif.id" :notif="notif"></notification>
</div>
</template>

<script>
import { mapState } from "vuex"
import Notification from "@/components/Notification.vue"

export default {
    components: { Notification },
    computed: mapState(['notifications'])
}
</script>
