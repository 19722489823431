import { ref } from 'vue';
import { Notification } from '@/classes/Notification';
import { useStore } from 'vuex';
import axios from 'axios'

const restful = (initialUrl, initialValue) => {
    const content = ref((initialValue !== undefined) ? initialValue : {});
    var loading = ref(false);
    const store = useStore();

    const req = (method, config) => {

        const { url, data } = config;

        const requestUrl = (url !== undefined) ? url : initialUrl;
        const requestData = (data !== undefined) ? data : content.value;

        loading.value = true;

        const requestConfig = {
            method: method,
            url: requestUrl,
            data: requestData
        };

        if(data === null) {
            delete requestConfig.data;
        }

        return axios.request(requestConfig);
    };

    const get = async (config) => {

        if(config === undefined) {
            config = {};
        }

        try {
            config.data = null;
            const response = await req('get', config);

            if(config.morph) {
                config.morph(response.data);
            } else {
                content.value = response.data;
            }

        } catch(e) {
            if(config.onError === undefined || !config.onError(e)) {
                store.dispatch('notify', Notification.createFromException(e));
            }
        } finally {
            loading.value = false;
        }
    };

    const del = async (config) => {

        if(config === undefined) {
            config = {};
        }

        try {
            const response = await req('delete', config);


            if(config.morph) {
                config.morph(response.data);
            } else {
                content.value = response.data;
            }

            store.dispatch('notify', Notification.createSuccess('Deleted'));
        } catch(e) {
            store.dispatch('notify', Notification.createFromException(e));
        } finally {
            loading.value = false;
        }
    };

    const post = async (config) => {

        if(config === undefined) {
            config = {};
        }

        try {
            const response = await req('post', config);

            if(config.morph) {
                config.morph(response.data);
            } else {
                content.value = response.data;
            }

            store.dispatch('notify', Notification.createSuccess('Stored'));
        } catch(e) {
            store.dispatch('notify', Notification.createFromException(e));
        } finally {
            loading.value = false;
        }
    };

    const put = async (config) => {

        if(config === undefined) {
            config = {};
        }

        try {
            const response = await req('put', config);


            if(config.morph) {
                config.morph(response.data);
            } else {
                content.value = response.data;
            }

            store.dispatch('notify', Notification.createSuccess('Saved'));
        } catch(e) {
            store.dispatch('notify', Notification.createFromException(e));
        } finally {
            loading.value = false;
        }
    };

    return { get, post, del, put, content, loading };
};

export { restful };
