import { ref } from "vue";

class Notification {
    constructor(type, title, body) {
        this.type = type;
        this.title = title;
        this.body = body;
        this.shown = ref(true);
    }

    static createError(title, body) {
        return new Notification('error', title, body);
    }

    static createSuccess(title, body) {
        return new Notification('success', title, body);
    }

    static createWarning(title, body) {
        return new Notification('warning', title, body);
    }

    static createFromException(e) {
        if(e.response == null) {
            // we have no idea what happened
            return new Notification('error', e.name, e.message);
        } else {

            if(e.response.data != null) {
                if(Object.hasOwn(e.response.data, "message")) {
                    // laravel verification API
                    return new Notification('error', e.response.statusText, e.response.data.message);
                }

                // laravel exception format
                if(Object.hasOwn(e.response.data, "error")) {
                    return new Notification('error', e.response.statusText, e.response.data.error);
                }

                // we have no clue
                return new Notification('error', e.response.statusText, e.response.data);
            } else {

                // no response, we can assume its no internet
                return new Notification('error', 'Failed to connect', e.message);

            }

        }
    }
}

export { Notification };
